<template>
    <div class="channel-container">
      <h2 class="channel-title">Channel Providers</h2>
      <br /><br />
      <input
        type="text"
        placeholder="Search"
        class="search-input-box"
        v-model="searchQuery"
      />
      <br /><br />
      <table>
        <thead>
          <tr>
            <th>Provider</th>
            <th>Channel</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(channel, index) in displayedChannels" :key="index" @click="openUrl(channel.url)" :class="index % 2 === 0 ? 'even-row' : 'odd-row'">
            <td>{{ channel.name }}</td>
            <td>{{ channel.channel }}</td>
          </tr>
          <tr v-if="filteredChannels.length === 0 && searchQuery !== ''">
            <td colspan="2" class="odd-row">No Results Found</td>
          </tr>
        </tbody>
      </table>
  
      <button class="show-more-button" @click="toggleShowAllRows" v-show="filteredChannels.length > rowsToShow">
        {{ showAllRows ? "Show Less" : "Show More" }}
      </button>
    </div>
  </template>
  
  <script>
  import { getTelevisionProviderInformation } from "../../../../../services/channels";
  export default {
    name: "ChannelStaticList",
    data() {
      return {
        channels: [],
        searchQuery: "",
        showAllRows: false,
        rowsToShow: 5, // Number of rows to show initially
      };
    },
    computed: {
    filteredChannels() {
      if (this.searchQuery) {
        return this.channels.filter((channel) =>
          channel.name.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      } else {
        return this.channels;
      }
    },
    displayedChannels() {
      // Limit the number of rows displayed based on the 'showAllRows' and 'rowsToShow' properties.
      return this.showAllRows
        ? this.filteredChannels
        : this.filteredChannels.slice(0, this.rowsToShow);
    },
  },
  methods: {
    toggleShowAllRows() {
      this.showAllRows = !this.showAllRows;
    },
		openUrl(imageUrl){
			const imageUrlRoute = this.$router.resolve({ path: imageUrl });
			window.open(imageUrlRoute.href.slice(1), '_blank');
		}
  },
  mounted(){
		this.channels = getTelevisionProviderInformation();
  }
};
</script>

<style lang="scss" scoped>
  .channel-container {
    background-color: rgba(4, 4, 4, 0.678) !important;
    padding: 1.2rem;
    font-family: 'Inter', sans-serif;
    font-weight: 500; 
  }

  
  .search-input-box {
    border: 2px solid #EBBA0A;
    font-size: 1.1rem;
    width: 100%;
    box-sizing: border-box;    
    opacity: 1;
  }
  h2.channel-title{
    font-size:1.7rem;
    color: #ffffff !important; 
    font-weight: bold;
  }
  
  table {
    width: 100%;
    color: white;
  }
  
  th {
    color: #EBBA0A;
    font-size: 1.3rem;
    padding-left: 1rem;
  }
  
  td {
    padding: 0.8rem;
  }
  .even-row,
  .odd-row{
    font-size: 1.2rem;
  }
  
  .even-row {
    background-color: #191919;
  }
  
  .odd-row {
    background-color: #141414;
  }

  .even-row:hover, .odd-row:hover {
    cursor: pointer;
    color: #EBBA0A;
  }
  .show-more-button {
  background-color: #EBBA0A;
  color: black;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  margin-top: 1rem;
}

.show-more-button:hover {
  background-color: #D9A90A;
}

.show-more-button:focus {
  outline: none;
}
  @media (max-width: 768px){
    td{
        padding: 0;
    }
    th,td{
        font-size: 1rem;
    }
  }
  </style>